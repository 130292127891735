<template>
  <div class="reviewChat">
    <!-- 头部 -->
    <div class="chatTop">
      <i class="el-icon-edit-outline"></i>
      Point Cashback
    </div>
    <!-- 底部 -->
    <div class="chatBtm">
      <el-button @click="dialogVisible=true">Request Cashback</el-button>
      <el-dialog title="reflect"
                 :modal-append-to-body="false"
                 :visible.sync="dialogVisible"
                 :show-close='false'
                 width="30%">
        <el-form :label-position="labelPosition"
                 label-width="120px"
                 :model="formLabelAlign">
          <el-form-item label="PayPal account">
            <el-input v-model="formLabelAlign.paypal"></el-input>
          </el-form-item>
          <el-form-item label="Consumption points">
            <el-input v-model="formLabelAlign.point" placeholder="You can only extract points beyond 200 points"></el-input>
          </el-form-item>
        </el-form>
        <el-button @click="cancel()">cancle</el-button>
        <el-button type="primary"
                   @click="reflect()" :loading="$store.state.loadingBool">confirm</el-button>
      </el-dialog>
      <el-table :data="tableData"
                border
                style="width: 100%">
        <el-table-column prop="paypal_account"
                         label="Paypal Account">
        </el-table-column>
        <el-table-column prop="id"
                         label="Trading No">
        </el-table-column>
        <el-table-column prop="amount"
                         label="Amount">
        </el-table-column>
        <el-table-column prop="currency"
                         label="Currency">
        </el-table-column>
        <el-table-column prop="expend_score"
                         label="Expend Score">
        </el-table-column>
        <el-table-column prop="status"
                         label="Status">
        </el-table-column>
        <el-table-column prop="memo"
                         label="Memo">
        </el-table-column>
        <el-table-column prop="update_date"
                         label="Update Date">
                         <template slot-scope="scope">
                            {{scope.row.update_date | formatDate}}
                          </template>
        </el-table-column>
        <el-table-column prop="create_date"
                         label="Create Date">
                         <template slot-scope="scope">
                            {{scope.row.create_date | formatDate}}
                          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="block">
      <div class="blockDiv">
        <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[10, 20, 50, 100]"
                         :page-size="currentSize"
                         layout="total,sizes, prev, pager, next, jumper"
                         :total="currentTotal">
          </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// 引入接口
import {
  pointcashback, // 体现接口
  seachPoint // 体现记录
} from '../../api/buyerAfter'
import {
  userQuery // 查询个人资料
} from '../../api/buyerFront'
// 日期格式化
import moment from 'moment'
export default {
  data () {
    return {
      // 表格列表
      tableData: [],
      // 对齐方式
      labelPosition: 'top',
      // 体现表单
      formLabelAlign: {
        paypal: '',
        point: ''
      },
      // 弹出层
      dialogVisible: false,
      // 分页
      currentPage: 1,
      currentSize: 10,
      currentTotal: 0
    }
  },
  created () {
    // // 查询个人资料
    userQuery().then(res => {
      // console.log(res)
      if (res.data.code === 200) {
        this.formLabelAlign.paypal = res.data.data.paypal_account
      }
    })
    this.getSeachPoint(this.currentPage, this.currentSize)
    this.$store.commit('getLoading', false)
  },
  methods: {
    // 体现记录
    getSeachPoint (currentPage, currentSize) {
      this.currentSize = currentSize
      // 体现记录
      seachPoint({
        page: currentPage,
        size: currentSize
      }).then(result => {
        if (result.data.code === 200) {
          this.tableData = result.data.data.content
          // this.tableData.map(v => {
          //   v.create_date = moment(v.create_date).format('YYYY-MM-DD hh:mm')
          //   v.update_date = moment(v.update_date).format('YYYY-MM-DD hh:mm')
          // })
          this.currentTotal = result.data.data.total_elements
        }
      }).catch(err => {
        return err
      })
    },
    // cancle
    cancel () {
      this.dialogVisible = false
      this.formLabelAlign.paypal = ''
      this.formLabelAlign.point = ''
    },
    // confirm
    reflect () {
      const email = /^[A-Za-zd0-9]+([-_.][A-Za-zd]+)*@([A-Za-zd]+[-.])+[A-Za-zd]{2,5}$/
      if (!email.test(this.formLabelAlign.paypal)) {
        this.$message.error('Please enter the correct email address')
      } else {
        this.$store.commit('getLoading', true)
        pointcashback({
          expend_score: this.formLabelAlign.point,
          paypal_account: this.formLabelAlign.paypal
        }).then(result => {
          if (result.data.code === 200) {
            this.$store.commit('getLoading', false)
            this.$message.success(result.data.message)
            this.dialogVisible = false
            this.getSeachPoint(this.currentPage, this.currentSize)
          } else {
            this.$store.commit('getLoading', false)
            this.$message.error(result.data.message)
          }
          // ('result ==>', result)
        }).catch(err => {
          return err
        })
      }
    },
    handleSizeChange (val) {
      // (`每页 ${val} 条`)
      this.getSeachPoint(this.currentPage, val)
    },
    handleCurrentChange (val) {
      this.getSeachPoint(val, this.currentSize)
    }
  }
}
</script>

<style lang="less" scoped>
.reviewChat {
  .chatTop {
    padding: 12px 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c8ced3;
    line-height: 1.5;
    .el-icon-edit-outline {
      font-size: 14px;
      margin-right: 20px;
    }
  }
  .chatBtm {
    min-height: 100px;
    padding: 20px;
    background-color: white;
    .el-button {
      background-color: #20a8d8;
      border-color: #20a8d8;
      font-size: 16px;
      color: white;
      font-weight: bolder;
      margin-bottom: 10px;
    }
  }
}
</style>
